import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <span class='homeText'>
        <p>
        <b>Clear, straightforward, pragmatic advice on technology, strategy, product design and governance.</b>
        </p>
        <p>
        Making technology easy to understand and use has been my career for over 25 years.</p>
        <p>If you need strategic, practical advice on how to purchase, build, deploy or use technology, or to improve governance and resilience, I can help.
        </p>
        <a href='mailto:contact@davidbraziel.co.uk'>contact@davidbraziel.co.uk</a>
        </span>

      </header>
    </div>
  );
}

export default App;
